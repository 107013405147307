<template>
  <!-- 十分钟是因为设备十分钟存一次，不能跨文件播放，只能先获取右上角时间列表 -->
  <div class="main noScroll">
    <div class="top"
         v-if="getTabAndListVisible">
      <div class="top-cont">
        <div class="top-cont-logo"
             @click="$router.replace('/main')">
          <img :src="loginLogo || loginLogoDefault">
          <span>{{systemName}}</span>
        </div>
        <div class="top-cont-tabs">
          <div @click="onClickTabs(0)"
               :class="{active:currentTabIndex === 0}">视频监控</div>
          <div @click="onClickTabs(1)"
               :class="{active:currentTabIndex === 1}">视频回放</div>
          <div @click="onClickTabs(2)"
               :class="{active:currentTabIndex === 2}">录像文件</div>
          <div @click="onClickTabs(3)"
               :class="{active:currentTabIndex === 3}">录像计划</div>
        </div>
      </div>
    </div>
    <div class="cont"
         :style="{'padding-top': getTabAndListVisible ? '60px' : '0'}">
      <div class="cont-left"
           v-if="getTabAndListVisible">
        <div class="left">
          <!-- 列表 -->
          <div class="left-list">
            <div class="video-tree-title">
              <div class="video-tree-title-left">
                <Icon custom="i-icon icon-zuojiantou"
                      size="16"
                      color="rgba(255,255,255,0.65)"
                      v-show="currentMenuIndex!==0"
                      @click="onClickConcern(0)" />
                <span>{{leftVideoTitle}}</span>
              </div>
              <div class="video-tree-title-right">
                <Icon custom="i-icon icon-chaxunsousuo"
                      size="24"
                      color="#7B7B7D"
                      class="video-tree-title-right-icon"
                      @click.stop="onClickSearchIcon"
                      v-show="currentMenuIndex!==1" />
                <Icon custom="i-icon icon-shaixuan1"
                      size="20"
                      :color="filterVisible ? '#25BB95' : '#7B7B7D'"
                      class="video-tree-title-right-icon"
                      @click.stop="onClickFilterIcon" />
                <!-- 添加组的图标 -->
                <Icon custom="i-icon icon-tianjiazu"
                      size="18"
                      color="#7B7B7D"
                      v-show="currentMenuIndex===1"
                      @click.stop="onClickGroupModel" />
              </div>
            </div>
            <div class="video-tree-info"
                 v-if="currentMenuIndex===0">
              <div class="total">
                <img src="../../assets/video/list_video.png">
                <div>
                  <p>全部摄像头</p>
                  <span>{{totalNum}}</span>
                </div>
              </div>
              <div class="online">
                <Icon custom="i-icon icon-zaixian2"
                      size="20"
                      color="#ffffff"
                      class="online-icon" />
                <div>
                  <p>在线摄像头</p>
                  <span>{{onlineNum}}</span>
                </div>
              </div>
            </div>
            <div class="video-tree-info"
                 v-if="currentMenuIndex===2">
              <div class="concern">
                <img src="../../assets/video/list_concern.png">
                <div>
                  <p>关注</p>
                  <span>0</span>
                </div>
              </div>
              <div class="online">
                <Icon custom="i-icon icon-zaixian2"
                      size="20"
                      color="#7B7B7D"
                      class="online-icon" />
                <div>
                  <p>在线摄像头</p>
                  <span>69</span>
                </div>
              </div>
            </div>
            <!-- 筛选  搜索不显示时显示 -->
            <div class="video-tree-filter"
                 v-show="filterVisible">
              <MyTreeSelect :value="departName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            @onSelectChange="onSelectChangeDepart"
                            style="width:148px">
              </MyTreeSelect>
              <Select v-model="objectType"
                      placeholder="监控对象"
                      clearable
                      @on-change="getList"
                      style="width:110px;margin: 0 4px;">
                <Option v-for="item in objectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="status"
                      placeholder="状态"
                      clearable
                      @on-change="getList"
                      style="width:110px">
                <Option v-for="item in statusArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <!-- 列表 搜索不显示时显示 -->
            <div class="video-tree-list">
              <template v-if="data.length>0">
                <Collapse simple>
                  <Panel v-for="(item,index) in data"
                         :key="index"
                         class="item">
                    <div class="item-parent"
                         @click="onClickVideoPlayRow(item)">
                      <div class="item-parent-left">
                        <span>{{item.bindname}}</span>
                      </div>
                      <div class="item-parent-right">
                        <Icon custom="i-icon icon-jiankong11"
                              size="16"
                              :color="videoIndeterminate(item)?'#25bb96':item.onlinestatus==0?'#e3e5e8':'#505050'"
                              class="m-r-8"
                              :style="{cursor:(item.onlinestatus==1 && (currentTabIndex == 0 ||currentTabIndex == 1))?'not-allowed':''}"
                              @click.stop="onClickVideoPlay(item)" />
                        <!-- :style="{cursor:(currentTabIndex===0&&item.onlinestatus==1)?'not-allowed':''}" -->
                        <span>{{item.details.length}}</span>
                      </div>
                    </div>
                    <ul class="item-child"
                        slot="content">
                      <li v-for="(items,indexs) in item.details"
                          :key="indexs"
                          @click.stop="onClickChildVideoRow(item,items)">
                        <div class="li-left">
                          <span class="status"
                                :class="{'on-line':item.onlinestatus==0}">{{item.onlinestatus==0?'在线':'离线'}}</span>
                          <span class="name">{{items.channelname}}</span>
                        </div>
                        <div class="li-right">
                          <!-- <Icon custom="i-icon icon-bianji1"
                                size="18"
                                color="#83909D"
                                class="li-right-icon li-right-iconshow" />
                          <Icon custom="i-icon icon-guanzhu_xian"
                                size="18"
                                color="#83909D"
                                class="li-right-icon li-right-iconshow" /> -->
                          <Icon custom="i-icon icon-jiankong11"
                                size="18"
                                :color="items.checked?'#25bb96':item.onlinestatus==0?'#B5B5B5':'#505050'"
                                class="li-right-icon"
                                :style="{cursor:(item.onlinestatus==1 && (currentTabIndex == 0 ||currentTabIndex == 1))?'not-allowed':''}"
                                @click.stop="onClickChildVideoPlay(item,items)" />
                          <!-- :style="{cursor:(currentTabIndex===0&&item.onlinestatus==1)?'not-allowed':''}" -->
                        </div>
                      </li>
                    </ul>
                  </Panel>
                </Collapse>
              </template>
              <div class="noData"
                   v-else>
                <img src="../../assets/main/blank_black.png">
                <p>暂无数据</p>
              </div>
            </div>
          </div>
          <!-- 查询时间 视频回放显示时才显示 -->
          <div class="left-inquire"
               v-show="currentTabIndex===1">
            <p class="inquire-title">查询时间</p>
            <ul class="inquire-time">
              <li @click="onClickPlaybackTabs(0)"
                  :class="{current:currentPlaybackTabsIndex == 0}">前半小时</li>
              <li @click="onClickPlaybackTabs(1)"
                  :class="{current:currentPlaybackTabsIndex == 1}">前1小时</li>
              <li @click="onClickPlaybackTabs(2)"
                  :class="{current:currentPlaybackTabsIndex == 2}">前2小时</li>
            </ul>
            <div class="inquire-picker">
              <p>日期</p>
              <DatePicker type="date"
                          :value="date"
                          :start-date="new Date()"
                          placeholder=""
                          @on-change="onChangeDate"
                          style="width: 270px"></DatePicker>
            </div>
            <div class="inquire-picker">
              <p>时间</p>
              <TimePicker type="timerange"
                          :value="time"
                          placeholder=""
                          :editable="false"
                          @on-change="onChangeTime"
                          style="width: 270px"></TimePicker>
            </div>
            <div class="inquire-btns">
              <Button @click="onClickResetPlayback">重置</Button>
              <Button @click="onClickVideoPlayback">确认</Button>
            </div>
          </div>
          <!-- 轮循组 搜索不显示，列表显示，视频回放不显示，时才显示 -->
          <!-- <div class="left-carousel"
               v-show="currentMenuIndex!==1&&currentTabIndex===0"
               @click="onClickConcern(1)">
            <div class="carousel-left">
              <Icon custom="i-icon icon-lunxun"
                    size="18"
                    color="#F9AD13"
                    class="" />
              轮循组
            </div>
            <div class="carousel-right">
              <Icon custom="i-icon icon-xiayige"
                    size="14"
                    color="rgba(255,255,255,0.25)"
                    class="" />
            </div>
          </div> -->
          <!-- 我的关注 搜索不显示，列表显示，时才显示 -->
          <!-- <div class="left-concern"
               v-show="currentMenuIndex!==2"
               @click="onClickConcern(2)">
            <div class="concern-left">
              <Icon custom="i-icon icon-zongheguanli_guanzhu"
                    size="18"
                    color="#F9AD13"
                    class="" />
              我的关注
            </div>
            <div class="concern-right">
              <span>0</span>
              <Icon custom="i-icon icon-xiayige"
                    size="14"
                    color="rgba(255,255,255,0.25)"
                    class="" />
            </div>
          </div> -->
        </div>
      </div>
      <!-- 搜索列表 -->
      <div class="cont-search"
           v-show="listSearchVisible"
           :style="{bottom:currentTabIndex==1?'275px':'0'}">
        <div class="search-input">
          <Icon custom="i-icon icon-guanbi"
                size="20"
                color="#fff"
                @click.stop="onClickSearchIcon" />
          <input v-model="listSearchValue"
                 placeholder="请输入车牌号或设施名称"
                 style="width: 300px"
                 ref="listSearchInput" />
          <Icon custom="i-icon icon-chaxunsousuo"
                size="20"
                color="#fff"
                class="" />
        </div>
        <div class="video-tree-list">
          <!-- <div class="item"
               v-for="(item,index) in searchList"
               :key="index">
            <div class="item-parent">
              <div class="item-left">
                <Icon custom="i-icon icon-leixing__che"
                      size="18"
                      color="#AFAFAF" />
                <span>{{item.bindname}}</span>
              </div>
            </div>
            <ul class="item-child">
              <li v-for="(items,indexs) in item.details"
                  :key="indexs">
                <div class="li-left">
                  <span class="status"
                        :class="{'on-line' : items.onlinestatus == 0}">{{items.onlinestatus == 0 ? '在线' : '离线'}}</span>
                  <span class="name">{{items.channelname}}</span>
                </div>
              </li>
            </ul>
          </div> -->
          <Collapse simple>
            <Panel v-for="(item,index) in searchList"
                   :key="index"
                   class="item">
              <div class="item-parent">
                <div class="item-parent-left">
                  <span>{{item.bindname}}</span>
                </div>
                <div class="item-parent-right">
                  <Icon custom="i-icon icon-jiankong11"
                        size="16"
                        :color="videoIndeterminate(item)?'#25bb96':item.onlinestatus==0?'#e3e5e8':'#505050'"
                        class="m-r-8"
                        :style="{cursor:(currentTabIndex===0&&item.onlinestatus==1)?'not-allowed':''}"
                        @click.stop="onClickVideoPlay(item)" />
                  <span>{{item.details.length}}</span>
                </div>
              </div>
              <ul class="item-child"
                  slot="content">
                <li v-for="(items,indexs) in item.details"
                    :key="indexs"
                    @>
                  <div class="li-left">
                    <span class="status"
                          :class="{'on-line':item.onlinestatus==0}">{{item.onlinestatus==0?'在线':'离线'}}</span>
                    <span class="name">{{items.channelname}}</span>
                  </div>
                  <div class="li-right">
                    <!-- <Icon custom="i-icon icon-bianji1"
                          size="18"
                          color="#83909D"
                          class="li-right-icon li-right-iconshow" />
                    <Icon custom="i-icon icon-guanzhu_xian"
                          size="18"
                          color="#83909D"
                          class="li-right-icon li-right-iconshow" /> -->
                    <Icon custom="i-icon icon-jiankong11"
                          size="18"
                          :color="items.checked?'#25bb96':item.onlinestatus==0?'#B5B5B5':'#505050'"
                          class="li-right-icon"
                          :style="{cursor:(currentTabIndex===0&&item.onlinestatus==1)?'not-allowed':''}"
                          @click.stop="onClickChildVideoPlay(item,items)" />
                  </div>
                </li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div class="cont-right"
           ref="contRightRef"
           v-show="currentTabIndex ==0||currentTabIndex==1">
        <div class="right-video">
          <div class="right-video-box clear"
               :class="classes">
            <div class="right-video-item"
                 v-for="item,index in playVideoArr"
                 :key="item.equipcode+'-'+item.channelid"
                 style="color: #FFFFFF;font-size: 20px;">
              <div class="right-video-item-top">
                <p>{{item.bindname}} - {{item.channelname}}</p>
                <p class="cursor-pointer"
                   @click.stop="onClosePlayVideo(index,item)">关闭</p>
              </div>
              <!-- <div class="w-full h-full flex-1"> -->
              <SocketVideoPlayer :dataId="item.equipcode"
                                 :channelId="item.channelid"
                                 :startTime="playbackStartTime"
                                 :endTime="playbackEndTime"
                                 @onSearchPlaybackVideoList="onSearchPlaybackVideoList"
                                 ref="socketVideoPlayer"
                                 style="width:100%;height:100%"
                                 v-if="item.eqptype==='车载监控'"></SocketVideoPlayer>
              <HttpVideoPlayer :dataId="item.equipcode"
                               :channelId="item.channelid"
                               :playbackCurrentTime="playbackCurrentTime"
                               :startTime="playbackStartTime"
                               :endTime="playbackEndTime"
                               @onSearchPlaybackVideoList="onSearchPlaybackVideoList"
                               @getVideoMsg="getFaclVideoMsg"
                               @downFaclVideoErr="downFaclVideoErr"
                               @onPlayFaclVideo="onPlayFaclVideo"
                               @onPause="onPause"
                               @onPlay="onPlay"
                               @onStop="onStop"
                               @onFinishFastForward="onFinishFastForward"
                               @getPlayVideoMsg="getPlayVideoMsg"
                               :videoId="1"
                               ref="httpVideoPlayer"
                               style="width:100%;height:100%"
                               v-if="item.eqptype==='视频设备'"
                               v-show="!playVideo"></HttpVideoPlayer>
              <OrdinaryVideo :dataId="item.equipcode"
                             :channelId="item.channelid"
                             :startTime="playbackStartTime"
                             :endTime="playbackEndTime"
                             :streamId="streamId"
                             @downFaclVideoErr="downFaclVideoErr"
                             @onPlayFaclVideo="onPlayFaclVideo"
                             ref="ordinaryVideo"
                             style="width:100%;height:100%"
                             v-if="item.eqptype==='视频设备'"
                             v-show="playVideo"></OrdinaryVideo>
              <!-- </div> -->
            </div>
            <div class="right-video-item"
                 v-for="(item,index) in playVideoEmptyCount"
                 :key="index"
                 style="color: #FFFFFF;font-size: 20px;">
              <Icon custom="i-icon icon-default_video1"
                    size="56"
                    color="#fff"
                    class="right-video-item-default" />
            </div>
          </div>
          <!-- 直播底部 -->
          <div class="top-nav"
               v-show="currentTabIndex===0"
               v-if="noFullScreen">
            <div class="flex a-center">
              <div class="cursor-pointer"
                   @click.stop="onClickLock">
                <img :src="isLock ? lockImg : noLockImg"
                     style="width:25px;margin-left:10px">
              </div>
              <ul>
                <li @click.stop="onClickVideoCount(1)">
                  <img src="../../assets/video/1.png">
                </li>
                <li @click.stop="onClickVideoCount(4)">
                  <img src="../../assets/video/4.png">
                </li>
                <li @click.stop="onClickVideoCount('4-1')">
                  <img src="../../assets/video/4-1.png">
                </li>
                <li @click.stop="onClickVideoCount(6)">
                  <img src="../../assets/video/6.png">
                </li>
                <li @click.stop="onClickVideoCount('6-1')">
                  <img src="../../assets/video/6-1.png">
                </li>
                <li @click.stop="onClickVideoCount(9)">
                  <img src="../../assets/video/9.png">
                </li>
                <li @click.stop="onClickVideoCount(10)">
                  <img src="../../assets/video/10-2.png">
                </li>
                <li @click.stop="onClickVideoCount(12)">
                  <img src="../../assets/video/12.png">
                </li>
                <li @click.stop="onClickVideoCount(16)">
                  <img src="../../assets/video/16.png">
                </li>
              </ul>
            </div>
            <div class="right-icon">
              <!-- <Checkbox>平铺</Checkbox> -->
              <Icon custom="i-icon icon-quanping"
                    size="16"
                    color="#fff"
                    class="right-icon-icon"
                    @click="onClickFullScreen" />
              <span class="right-icon-text"
                    @click="onClickFullScreen">全屏</span>
            </div>
          </div>
          <!-- 回放底部进度条 -->
          <div class="playback-footer flex a-center j-between"
               v-show="currentTabIndex===1"
               v-if="sliderVisible">
            <div class="m-r-10 cursor-pointer"
                 @click="onClickFastForward(1)"
                 :class="{'slider-current':speed == 1}">1x</div>
            <div class="m-r-10 cursor-pointer"
                 @click="onClickFastForward(2)"
                 :class="{'slider-current':speed == 2}">2x</div>
            <div class="m-r-10 cursor-pointer"
                 @click="onClickFastForward(4)"
                 :class="{'slider-current':speed == 4}">4x</div>
            <div class="m-r-10 cursor-pointer"
                 @click="onClickFastForward(8)"
                 :class="{'slider-current':speed == 8}">8x</div>
            <!-- <div class="m-r-10">快退</div> -->
            <Slider class="videoSlider flex-1"
                    v-model="videoSlider"
                    show-tip="never"
                    @on-change="onChangeSlider"
                    :max="1000"></Slider>
          </div>
          <!-- 回放底部的列表 -->
          <div class="playback-listbtn"
               :style="{width:planBackListBrnWidth + 'px'}"
               v-if="currentTabIndex == 1 && noFullScreen">
            <div class="flex j-center o-hidden">
              <div @click.stop="onClickPlayBackTop"
                   class="playback-top cursor-pointer">
                <Icon type="ios-arrow-up" />
              </div>
              <div @click.stop="onClickPlayBackBottom"
                   class="playback-bottom m-l-20 cursor-pointer">
                <Icon type="ios-arrow-down" />
              </div>
            </div>
            <div v-show="playBackTableVisible"
                 class="playback-table">
              <div class="p-20">
                <!-- <Icon custom="i-icon ivu-icon-ios-close"
                      size="32"
                      color="black"
                      class="cursor-pointer binding-icon"
                      @click.stop="onClickHidePlaybackTable" /> -->
                <Table ref="table"
                       :height="recordTableHeight"
                       :columns="fileColumns"
                       :data="tableData"
                       border
                       :loading="tableLoading"
                       @on-selection-change="onChangeSelect"
                       @on-sort-change="onChangeSort">
                  <template slot-scope="{ row }"
                            slot="video">
                    <div v-previewVideo="row.url"
                         style="width: 24px;height: 24px;background: #797979;margin: auto;cursor: pointer"
                         v-if="row.url">
                      <Icon custom="i-icon icon-bofang"
                            size="16"
                            color='#fff'></Icon>
                    </div>
                  </template>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <!-- 回放列表 -->
        <div class="right-playback"
             v-show="currentTabIndex===1">
          <Poptip v-model="playbackVideoListVisible"
                  placement="bottom-start">
            <Icon custom="i-icon icon-liebiao1"
                  size="16"
                  color="#25BB96"
                  class="right-playback-icon" />
            <div slot="content">
              <div class="right-playback-box">
                <template v-if="playbackVideoArr.length>0">
                  <div class="flex">
                    <!-- 选择时间段内的时间 -->
                    <TimePicker :value="playbackProgressTime"
                                placeholder="选择拖放开始时间"
                                :editable="false"
                                :disabled="currentPlaybackVideoIndex===''"
                                @on-change="onChangePlaybackProgressTime"
                                style="width: 100%"></TimePicker>
                    <Button @click="onClickPlaybackProgress">确认</Button>
                  </div>
                  <div class="right-playback-list">
                    <div class="right-playback-item"
                         :class="currentPlaybackVideoIndex===index?'current':''"
                         v-for="item,index in playbackVideoArr"
                         :key="index"
                         @click.stop="onClickPlaybackVideoItem(item,index)">
                      <span>{{item.startTime}}</span>
                      <span>至</span>
                      <span>{{item.endTime}}</span>
                    </div>
                  </div>
                </template>
                <div class="noData"
                     v-else>
                  <img src="../../assets/main/blank_black.png">
                  <p>暂无数据</p>
                </div>
              </div>
            </div>
          </Poptip>
        </div>
        <!-- 下载进度环 -->
        <div class="down-circle"
             v-show="currentTabIndex===1"
             v-if="circleVisible && false">
          <i-circle :percent="downCircle"
                    :size="60"
                    :stroke-width="10"
                    :trail-width="10"
                    stroke-color="#51C9AB">
            <div v-if="downCircle == 100">
              <!-- <span @click="downFaclVideo">下载</span> -->
              <span @click="playFaclVideo"
                    class="cursor-pointer">播放</span>
            </div>
          </i-circle>
        </div>
        <!-- 录制按钮 -->
        <div class="right-recording"
             v-if="currentTabIndex == 1 && sliderVisible">
          <Button type="primary"
                  @click.stop="recordingVisible = !recordingVisible">
            录制
          </Button>
          <div class="right-recording-modal"
               v-show="recordingVisible"
               v-click-outside="handlerCloseGenDetail">
            <div class="right-recording-modal-item">
              <p class="item-title">录像时间段：</p>
              <TimePicker type="timerange"
                          :value="videoTime"
                          :disabled-hours="videoTimeDisabledHours"
                          :disabled-minutes="videoTimeDisabledMinutes"
                          :disabled-seconds="videoTimeDisabledSeconds"
                          @on-change="onChangeLengthTime"></TimePicker>
            </div>
            <div class="right-recording-modal-item">
              <p class="item-title">录制开始时间：</p>
              <RadioGroup v-model="videoStartTime">
                <Radio :label="1">
                  预约时间
                </Radio>
                <Radio :label="2">
                  即刻录制
                </Radio>
              </RadioGroup>
            </div>
            <div class="right-recording-modal-item"
                 v-show="videoStartTime == 1">
              <!-- <p class="item-title"></p> -->
              <DatePicker type="date"
                          :options="makeAnAppointmentDateOptions"
                          :value="makeAnAppointmentDate"
                          @on-change="onChangeRecordDate"
                          style="width:155px"
                          class="m-r-10"></DatePicker>
              <TimePicker type="time"
                          :value="makeAnAppointmentTime"
                          :disabled-hours="videoTimeDisabledHours"
                          :disabled-minutes="videoTimeDisabledMinutes"
                          :disabled-seconds="videoTimeDisabledSeconds"
                          @on-change="onChangeRecordTime"
                          style="width:155px"></TimePicker>
            </div>
            <div class="flex j-end m-b-10">
              <Button type="primary"
                      :loading="recordLoading"
                      @click.stop="onClickCreatedVideoPlan">
                确定
              </Button>
            </div>
            <div class="flex a-center">
              <Icon custom="i-icon icon-tishi1"
                    size="16"
                    color="#29bc98"
                    class="cursor-pointer m-r-10"></Icon>
              即刻录制可能导致网络卡顿，建议预约开始录制时间。
            </div>
          </div>
        </div>
      </div>
      <!-- 录像文件，录像计划  start -->
      <div class="flex-1 p-10 videotape"
           v-show="currentTabIndex == 2||currentTabIndex==3">
        <div class="flex j-between p-10">
          <template v-if="currentTabIndex ==2">
            <div class="flex a-center">
              <DatePicker type="datetimerange"
                          :value="fileDate"
                          :start-date="new Date()"
                          placeholder="请选择时间范围"
                          @on-change="onChangeFileDate"
                          style="width:320px"></DatePicker>
            </div>
            <div class="flex j-end">
              <Button type="primary"
                      class="m-l-10"
                      @click.stop="onClickSearch">
                <Icon custom="i-icon icon-sousuo"
                      size="16"></Icon>
                查询
              </Button>
              <Button class="m-l-10"
                      @click.stop="onClickReset">
                <Icon custom="i-icon icon-shuaxin"
                      size="16"></Icon>
                重置
              </Button>
            </div>
          </template>
          <template v-if="currentTabIndex == 3">
            <div>
              <Button type="primary"
                      class="m-r-10"
                      @click.stop="videoProgramPlanVisible = true">
                设置计划
              </Button>
              <!-- <Button type="primary"
                      @click.stop="onClickDeletePlan">
                批量删除
              </Button> -->
            </div>
            <div>
              <Button class="m-l-10 m-r-10"
                      @click.stop="onClickReset">
                <Icon custom="i-icon icon-shuaxin"
                      size="16"></Icon>
                重置
              </Button>
              <Icon custom="i-icon icon-shezhi"
                    size="16"
                    color="#51c9ab"
                    class="cursor-pointer"
                    @click.stop="videoProgramSetVisible = true"></Icon>
            </div>
          </template>
        </div>
        <div class="table">
          <Table ref="table"
                 :width="tableWidth"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="tableData"
                 border
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button type="text"
                      class="tableOperaBtn"
                      @click.stop="onClickEditVideoPlan(row)">编辑</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      @click.stop="onClickDeleteVideoPlan(row)">删除</Button>
            </template>
            <template slot-scope="{ row }"
                      slot="video">
              <div @click.stop="onClickPreviewVideo(row)"
                   style="width: 24px;height: 24px;margin: auto;cursor: pointer"
                   :style="{'background':row.status == '2' ? '#25bb96' : '#595858'}">
                <Icon custom="i-icon icon-bofang"
                      size="16"
                      color='#fff'></Icon>
              </div>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
      <!-- 录像文件，录像计划 end -->
    </div>

    <!-- 添加轮循组 -->
    <Modal v-model="groupModelVisible"
           title="新建轮循组">
      <div class="group">
        <div class="group-title">名称</div>
        <Input v-model="groupValue"
               placeholder=""
               style="width: 300px"></Input>
      </div>
    </Modal>

    <!-- 录像计划设置 -->
    <VideoProgramSetModel v-model="videoProgramSetVisible"
                          @onClickConfirm="updateVideoConfig(true)"></VideoProgramSetModel>
    <!-- 设置录像计划 -->
    <VideoProgramPlanModel v-model="videoProgramPlanVisible"
                           :item.sync="videoProgramPlanItem"
                           @onClickConfirm="getVideoPlanList"></VideoProgramPlanModel>

    <!-- 预览视频 -->
    <PreviewVideo :videoUrl.sync="previewVideoUrl"></PreviewVideo>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { debounce } from '@/utils/debounceAndThrottle';
import { formatDate, getBeforeHourTimeNoStrideDay, isInDateInterval, formatTime, hourMillisecond, dayMillisecond } from '@/utils/dateRangUtil';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import SocketVideoPlayer from '@/components/common/SocketVideoPlayer';
import HttpVideoPlayer from '@/components/common/HttpVideoPlayer';
import OrdinaryVideo from '@/components/common/OrdinaryVideo';
import VideoProgramSetModel from '@/components/product/video/VideoProgramSetModel';
import VideoProgramPlanModel from '@/components/product/video/VideoProgramPlanModel';
import PreviewVideo from '@/components/common/PreviewVideo';
import constantconfig from '../../config/constantconfig'
import vClickOutside from 'v-click-outside'
import { download } from '@/utils/utils'
import { closeFlacDemandVideo } from '@/utils/videoFacl'
export default {
  components: {
    MyTreeSelect,
    SocketVideoPlayer,
    HttpVideoPlayer,
    OrdinaryVideo,
    VideoProgramSetModel,
    VideoProgramPlanModel,
    PreviewVideo
  },
  directives: { clickOutside: vClickOutside.directive },
  data () {
    return {
      makeAnAppointmentDateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - dayMillisecond
        }
      },
      loginLogoDefault: require('../../assets/login/logo.png'),
      currentTabIndex: 0,
      currentMenuIndex: 0,
      leftVideoTitle: '监控列表',
      listSearchValue: '',
      departId: '',
      departName: '',
      objectType: '',
      status: 0,
      statusArr: [
        {
          id: 0,
          name: '全部状态'
        },
        {
          id: 1,
          name: '在线'
        },
        {
          id: 2,
          name: '离线'
        }
      ],
      totalNum: 0,
      onlineNum: 0,
      data: [],
      // 筛选
      filterVisible: false,
      // 搜索
      listSearchVisible: false,
      searchList: [],
      // 添加轮循组的对话框
      groupModelVisible: false,
      groupValue: '',
      playVideoArr: [],
      playVideoCount: 1,
      videoClass: 'video-one',
      currentPlaybackTabsIndex: 0,
      date: '',
      time: [],
      playbackCurrentTime: 0,   // 重连的开始时间
      playbackStartTime: '',
      playbackEndTime: '',
      playbackVideoListVisible: false,
      playbackProgressTime: '',
      playbackVideoArr: [],
      currentPlaybackVideoIndex: '',
      noFullScreen: true,    // 是否不全屏
      downCircle: 0,   // 回放进度环进度
      videoSlider: 0,   // 回放视频进度条进度
      sliderVisible: false,
      circleVisible: false,
      faclDownloadTime: 0,   // 设施视频下载时间
      faclInterval: null,   // 进度环倒计时
      faclPlayInterval: null,   //进度条倒计时
      videoLingth: null,    // 视频毫秒数
      downloadFaclVideoParams: {},    // 视频下载的参数，获取开始时间与结束时间
      playVideo: false,   // 是否播放下载后的视频 应该已经弃用 2021-12-23 09:46:08
      streamId: '',   // 流id
      videoPause: false,   // 视频是否暂停
      videoLoading: '',     // 视频loading状态
      isLock: false,
      lockImg: require('../../assets/video/lock.png'),
      noLockImg: require('../../assets/video/noLock.png'),
      speed: 1,
      // 录制弹窗
      recordingVideoData: {},
      recordingVisible: false,
      videoTime: [],
      videoTimeDisabledHours: [],
      videoTimeDisabledMinutes: [],
      videoTimeDisabledSeconds: [],
      videoStartTime: 1,
      makeAnAppointmentDate: '',
      makeAnAppointmentTime: '',
      recordLoading: false,
      playBackTableVisible: false,
      playBackTableStatus: 0,   // 0没有 1半屏 2全屏
      recordTableHeight: 0,
      planBackListBrnWidth: 0,
      videoChannel: '',
      videoEquipcode: '',
      // 表格样式
      fileDate: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      tableData: [],
      select: [],
      selectArr: [],
      fileColumns: [
        // {
        //   type: 'selection',
        //   width: 45,
        //   align: 'center'
        // },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '播放',
          key: 'bindobjname',
          width: 40,
          tooltip: true,
          align: 'center',
          slot: 'video'
        },
        {
          title: '绑定对象名称',
          key: 'bindobjname',
          minWidth: 100,
          tooltip: true,
          align: 'center',
        },
        {
          title: '通道',
          key: 'channel',
          minWidth: 180,
          tooltip: true,
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createTime',
          minWidth: 100,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '录制开始时间',
          key: 'recordTime',
          minWidth: 140,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '开始时间',
          key: 'videoStartTime',
          minWidth: 140,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '结束时间',
          key: 'videoEndTime',
          minWidth: 140,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '录像时长',
          key: 'videoDuration',
          width: 70,
          tooltip: true,
          align: 'center',
        },
        {
          title: '录像大小',
          key: 'videoSize',
          width: 70,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.videoSize ? row.videoSize + 'M' : '');
          }
        },
        {
          title: '进度',
          key: 'imgUrlOk',
          width: 100,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text
            if (row.status == 0) {
              text = '录制未开始'
              return h('span', text);
            } else if (row.status == 1) {
              text = '录制中'
              return h('span', text);
            } else if (row.status == 2) {
              text = '录制完成'
              return h('div', [
                h('Button', {
                  props: {
                    size: 'small',
                    type: 'primary'
                  },
                  on: {
                    click: () => {
                      this.onClickDownVideo(row)
                    }
                  }
                }, '下载'),
              ]);
            } else if (row.status == 3) {
              text = '已失效'
              return h('span', text);
            }
          }
        },
      ],
      planColumns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '绑定对象名称',
          key: 'bindobjname',
          minWidth: 100,
          tooltip: true,
          align: 'center',
        },
        {
          title: '通道',
          key: 'channel',
          minWidth: 120,
          tooltip: true,
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createTime',
          minWidth: 100,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '录制开始时间',
          key: 'recordTime',
          minWidth: 100,
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '开始时间',
          key: 'videoStartTime',
          minWidth: 100,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '结束时间',
          key: 'videoEndTime',
          minWidth: 100,
          tooltip: true,
          align: 'center',
          sortable: true,
        },
        {
          title: '录像时长',
          key: 'videoDuration',
          minWidth: 50,
          tooltip: true,
          align: 'center',
        },
        {
          title: '开启计划',
          key: 'status',
          width: 70,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text
            if (row.status == 0) {
              text = '不启用'
            } else if (row.status == 1) {
              text = '启用'
            } else if (row.status == 2) {
              text = '已删除'
            }
            return h('span', text);
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 210,
          align: 'center'
        }
      ],
      // 录像计划设置
      videoProgramSetVisible: false,
      // 设置录像计划
      videoProgramPlanVisible: false,
      videoProgramPlanItem: null,
      // 预览视频
      previewVideoUrl: ''
    };
  },
  watch: {
    currentTabIndex (newVal, oldVal) {
      if (newVal === 1) {
        this.onClickVideoCount(1)
        this.onClickPlaybackTabs(0)
      } else {
        this.date = ''
        this.time = []
        this.playbackStartTime = ''
        this.playbackEndTime = ''
      }
      if (oldVal == 0) {
        this.playVideoArr.forEach(item => {
          if (item.eqptype == '视频设备') {
            closeFlacDemandVideo(item)
          }
        })
      } else if (oldVal == 1) {
        if (this.playVideoArr[0]) {
          if (this.playVideoArr[0].eqptype == '视频设备') {
            this.$refs.httpVideoPlayer[0].onCloseFlacBackVideo()
          }
        }
      }
      this.playVideoArr = []
      this.syncVideoListCheck(this.data)
      if (this.filterVisible) {
        this.syncVideoListCheck(this.searchList)
      }
      this.resetSearchData()
      this.playBackTableVisible = false
      this.playBackTableStatus = 0
      this.videoChannel = ''    // 通道号
      this.videoEquipcode = ''  // 设备编码
      switch (newVal) {
        case 2:
          this.getVideoFileList()
          break;
        case 3:
          this.getVideoPlanList()
          break;
        default:
          break;
      }
    },
    playVideoArr (newVal) {
      this.currentPlaybackVideoIndex = ''
      this.playbackVideoArr = []
      // 进度环
      this.downCircle = 0
      clearInterval(this.faclInterval)
      // 进度条
      this.videoSlider = 0
      clearInterval(this.faclPlayInterval)
    },
    listSearchVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.listSearchInput.focus()
        })
      } else {
        this.listSearchValue = ''
      }
    },
    // 搜索环卫要素名称的input
    listSearchValue (newVal) {
      if (newVal) {
        this.getSearchVideoEquipmentAllList()
      } else {
        this.searchList = []
      }
    },
    // 回放查询时间
    time (newVal) {
      let millisecond = 1000 * 60 * 60    // 一小时
      this.videoTime = newVal
      let endTime = new Date(this.date + ' ' + newVal[1]).getTime()
      let recordTime = new Date(endTime + millisecond)
      this.makeAnAppointmentDate = formatDate(recordTime)
      this.makeAnAppointmentTime = formatTime(recordTime)
      this.initTime()
    },
    // playVideo (newVal) {
    //   if (newVal) {
    //     setTimeout(() => {
    //       this.playVideoArr.splice(0, 1)
    //       this.syncVideoListCheck(this.data)
    //       if (this.filterVisible) {
    //         this.syncVideoListCheck(this.searchList)
    //       }
    //     }, 200);
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      'loginLogo': 'getLoginLogo',
      'systemName': 'getSystemName',
      'departmentTreeArr': 'getDepartmentTreeList',
      'videoConfig': 'getVideoConfig'
    }),
    objectTypeArr () {
      let arr = [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 'car',
          name: '车辆'
        },
        {
          id: 'emp',
          name: '人员'
        },
      ]
      return arr.concat(this.$store.getters.getFacilityBigTypeAllList)
    },
    playVideoEmptyCount () {
      return this.playVideoCount - this.playVideoArr.length
    },
    classes () {
      return this.videoClass
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 2:
          columns = this.fileColumns
          break;
        case 3:
          columns = this.planColumns
          break;
        default:
          break;
      }
      return columns
    },
    // 判断列表与tab是否显示
    getTabAndListVisible () {
      if (!this.noFullScreen) {
        if (this.currentTabIndex == 0 || this.currentTabIndex == 1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.removeEventListener('resize', () => { })
    window.removeEventListener('keydown', () => { })
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateFacilityBigTypeAllList',
      'updateVideoConfig',
    ]),
    init () {
      this.planBackListBrnWidth = this.$refs.contRightRef.offsetWidth
      this.tableWidth = window.innerWidth - 420
      this.tableHeight = window.innerHeight - 180
      window.onresize = () => {
        if (this.noFullScreen) {
          this.planBackListBrnWidth = this.$refs.contRightRef.offsetWidth
        }
        this.tableWidth = window.innerWidth - 420
        this.tableHeight = window.innerHeight - 180
      }
      this.updateDepartmentTreeList()
      this.updateFacilityBigTypeAllList()
      this.updateVideoConfig()
      this.getList()
      this.resetVideoSize()
      this.keydown()
      this.initTime()
    },
    // 监听是否全屏
    resetVideoSize () {
      window.addEventListener('resize', () => {
        // 判断全屏
        let isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (!isFullscreen) {
          console.log('没有全屏');
          this.noFullScreen = true
        } else {
          console.log('全屏');
          this.noFullScreen = false
        }
      })
    },
    // 视频监控全屏
    onClickFullScreen () {
      //全屏
      let docElm = document.documentElement;
      //W3C
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }
      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    // 阻止F11默认事件
    keydown () {
      window.addEventListener('keydown', (event) => {
        if (event.keyCode === 122) {
          event.preventDefault()
          event.returnValue = false
          this.onClickFullScreen()
        }
      })
    },
    // 视频监控 视频回放 tabs
    onClickTabs (index) {
      this.currentTabIndex = index
    },
    // 列表 详情显示
    onClickVideoItem (item) {
      item.subShow = !item.subShow
    },
    // 搜索
    onClickSearchIcon () {
      this.listSearchVisible = !this.listSearchVisible
    },
    // 筛选
    onClickFilterIcon () {
      this.filterVisible = !this.filterVisible
    },
    // 选择部门
    onSelectChangeDepart (data) {
      this.departId = data.id
      this.departName = data.name
      this.getList()
    },
    getList () {
      switch (this.currentMenuIndex) {
        case 0:
          this.getVideoEquipmentAllList()
          break;
        default:
          break;
      }
    },
    // 获取视频监控列表
    getVideoEquipmentAllList () {
      let params = {
        deptid: this.departId,
        type: this.objectType ? this.objectType : '',
      }
      // if (this.departId) params.deptid = this.departId
      // if (this.objectType) params.type = this.objectType
      if (this.status) params.onlinestatus = this.status === 1 ? 0 : 1
      this.$http.getVideoEquipmentAllList(params).then(res => {
        if (res.code === 200) {
          let data = res.result
          this.totalNum = data.totalnum
          this.onlineNum = data.onlinenum
          this.data = data.vioList.map(item => {
            item.details.forEach(items => {
              items.checked = false
              items.eqptype = item.eqptype
              items.equipcode = item.equipcode
              items.bindname = item.bindname
            })
            return item
          })
          this.syncVideoListCheck(this.data)
        }
      })
    },
    // 获取搜索视频监控列表
    getSearchVideoEquipmentAllList: debounce(function () {
      let params = {
        bindname: this.listSearchValue,
      }
      this.$http.getVideoEquipmentAllList(params).then(res => {
        if (res.code === 200) {
          let data = res.result
          this.searchList = data.vioList.map(item => {
            item.details.forEach(items => {
              items.checked = false
              items.eqptype = item.eqptype
              items.equipcode = item.equipcode
              items.bindname = item.bindname
            })
            return item
          })
          this.syncVideoListCheck(this.searchList)
        }
      })
    }),
    // 视频是否选择
    videoIndeterminate (item) {
      let boo = false
      for (const items of item.details) {
        if (items.checked) {
          boo = true
          break
        }
      }
      return boo
    },
    // 点击设备一行
    onClickVideoPlayRow (item) {
      if (this.currentTabIndex === 2) {
        this.videoEquipcode = item.details[0].equipcode
        if (this.videoChannel) {
          this.videoChannel = ''
        }
        this.getVideoFileList()
      } else if (this.currentTabIndex === 3) {
        this.videoEquipcode = item.details[0].equipcode
        if (this.videoChannel) {
          this.videoChannel = ''
        }
        this.getVideoPlanList()
      }
    },
    // 点击设备播放
    onClickVideoPlay (item) {
      this.speed = 1
      if (this.faclPlayInterval) {
        clearInterval(this.faclPlayInterval)
      }
      let boo = !item.details.some(items => items.checked)
      if (this.currentTabIndex === 0) {
        if (item.onlinestatus == 1) return
        item.details.forEach(items => {
          items.checked = boo
        })
        this.setVideoPlay(boo, item.details)
      } else if (this.currentTabIndex === 1) {
        if (item.onlinestatus == 1) return
        if (boo) {
          this.onClickPlaybackTabs(0)
          this.videoPlayback(item.details[0])
          this.recordingVideoData = item.details[0]
        } else {
          this.onClosePlayVideo(0)
          this.recordingVideoData = {}
        }
      } else if (this.currentTabIndex === 2) {
        this.videoEquipcode = item.details[0].equipcode
        if (this.videoChannel) {
          this.videoChannel = ''
        }
        this.getVideoFileList()
      } else if (this.currentTabIndex === 3) {
        this.videoEquipcode = item.details[0].equipcode
        if (this.videoChannel) {
          this.videoChannel = ''
        }
        this.getVideoPlanList()
      }
    },
    // 点击通道一行
    onClickChildVideoRow (item, items) {
      if (this.currentTabIndex === 2) {
        this.videoEquipcode = items.equipcode
        this.videoChannel = items.channelid
        this.getVideoFileList()
      } else if (this.currentTabIndex === 3) {
        this.videoEquipcode = items.equipcode
        this.videoChannel = items.channelid
        this.getVideoPlanList()
      }
    },
    // 点击通道播放
    onClickChildVideoPlay (item, items) {
      this.speed = 1
      if (this.faclPlayInterval) {
        clearInterval(this.faclPlayInterval)
      }
      if (this.currentTabIndex === 0) {
        if (item.onlinestatus == 1) return
        items.checked = !items.checked
        this.setVideoPlay(items.checked, [items])
      } else if (this.currentTabIndex === 1) {
        if (item.onlinestatus == 1) return
        this.recordingVideoData = items
        this.onClickPlaybackTabs(0)
        this.videoPlayback(items)
      } else if (this.currentTabIndex === 2) {
        this.videoEquipcode = items.equipcode
        this.videoChannel = items.channelid
        this.getVideoFileList()
      } else if (this.currentTabIndex === 3) {
        this.videoEquipcode = items.equipcode
        this.videoChannel = items.channelid
        this.getVideoPlanList()
      }
    },
    // 是否锁定播放窗口数量
    onClickLock () {
      this.isLock = !this.isLock
    },
    // 设置视频播放
    setVideoPlay (value, arr) {
      let filterArr = this.playVideoArr.filter(item => !arr.some(items => item.id === items.id))
      if (value) {
        let newArr = arr.concat(filterArr)
        let deleteVideoArr = []
        if (this.isLock) {
          if (newArr.length > this.playVideoCount) {
            deleteVideoArr = newArr.slice(this.playVideoCount, newArr.length)
            newArr = newArr.slice(0, this.playVideoCount)
            deleteVideoArr.forEach(item => {
              if (item.eqptype == '视频设备') {
                closeFlacDemandVideo(item)
              }
            })
          }
        }
        if (newArr.length > this.playVideoCount) {
          if (newArr.length > 64) {
            this.vodeoScreens(64)
            deleteVideoArr = newArr.slice(this.playVideoCount, newArr.length)
            this.playVideoArr = newArr.slice(0, this.playVideoCount)
          } else if (newArr.length > 32) {
            this.vodeoScreens(64)
            this.playVideoArr = newArr
          } else if (newArr.length > 24) {
            this.vodeoScreens(32)
            this.playVideoArr = newArr
          } else if (newArr.length > 16) {
            this.vodeoScreens(24)
            this.playVideoArr = newArr
          } else if (newArr.length > 12) {
            this.vodeoScreens(16)
            this.playVideoArr = newArr
          } else if (newArr.length > 10) {
            this.vodeoScreens(12)
            this.playVideoArr = newArr
          } else if (newArr.length > 9) {
            this.vodeoScreens(10)
            this.playVideoArr = newArr
          } else if (newArr.length > 6) {
            this.vodeoScreens(9)
            this.playVideoArr = newArr
          } else if (newArr.length > 4) {
            this.vodeoScreens(6)
            this.playVideoArr = newArr
          } else if (newArr.length > 1) {
            this.vodeoScreens(4)
            this.playVideoArr = newArr
          } else {
            this.vodeoScreens(1)
            this.playVideoArr = newArr
          }
          deleteVideoArr.forEach(item => {
            if (item.eqptype == '视频设备') {
              closeFlacDemandVideo(item)
            }
          })
        } else {
          this.playVideoArr = newArr
        }
      } else {
        arr.forEach(item => {
          if (item.eqptype == '视频设备') {
            closeFlacDemandVideo(item)
          }
        })
        let newArr = filterArr
        this.playVideoArr = newArr
      }
      this.syncVideoListCheck(this.data)
      if (this.filterVisible) {
        this.syncVideoListCheck(this.searchList)
      }
    },
    // 同步videoList的checkbox
    syncVideoListCheck (arr) {
      arr.forEach(item => {
        item.details.forEach(items => {
          if (this.playVideoArr.some(itemss => itemss.id === items.id)) {
            items.checked = true
          } else {
            items.checked = false
          }
        })
      })
    },
    // 视频停止
    onClosePlayVideo (index, item) {
      setTimeout(() => {
        this.playVideo = false
        this.playVideoArr.splice(index, 1)
        if (item.eqptype == '视频设备') {
          if (this.currentTabIndex == 0) {
            closeFlacDemandVideo(item)
          } else {
            this.$refs.httpVideoPlayer[0].onCloseFlacBackVideo()
          }
        }
        this.syncVideoListCheck(this.data)
        if (this.filterVisible) {
          this.syncVideoListCheck(this.searchList)
        }
      }, 200);
    },
    // 点击分屏
    onClickVideoCount (count) {
      this.isLock = true
      this.vodeoScreens(count)
      this.playVideoArr = this.playVideoArr.slice(0, this.playVideoCount)
      this.syncVideoListCheck(this.data)
      if (this.filterVisible) {
        this.syncVideoListCheck(this.searchList)
      }
    },
    // 设置分屏
    vodeoScreens (count) {
      if (count == 1) {
        this.videoClass = 'video-one'
        this.playVideoCount = 1
      } else if (count == 4) {
        this.videoClass = 'video-four'
        this.playVideoCount = 4
      } else if (count == 6) {
        this.videoClass = 'video-six'
        this.playVideoCount = 6
      } else if (count == 9) {
        this.videoClass = 'video-nine'
        this.playVideoCount = 9
      } else if (count == 12) {
        this.videoClass = 'video-twelve'
        this.playVideoCount = 12
      } else if (count == 16) {
        this.videoClass = 'video-sixteen'
        this.playVideoCount = 16
      } else if (count == 24) {
        this.videoClass = 'video-twenty-four'
        this.playVideoCount = 24
      } else if (count == 32) {
        this.videoClass = 'video-thirty-two'
        this.playVideoCount = 32
      } else if (count == 64) {
        this.videoClass = 'video-sixty-four'
        this.playVideoCount = 64
      } else if (count == '4-1') {
        this.videoClass = 'video-four-one'
        this.playVideoCount = 4
      } else if (count == '6-1') {
        this.videoClass = 'video-six-one'
        this.playVideoCount = 6
      } else if (count == 10) {
        this.videoClass = 'video-ten-two'
        this.playVideoCount = 10
      }
    },
    // 收藏  轮循
    onClickConcern (index) {
      this.currentMenuIndex = index
      this.getList()
    },
    onClickGroupModel () {
      this.groupModelVisible = true
    },
    // 视频回放 查询时间tabs
    onClickPlaybackTabs (index) {
      this.currentPlaybackTabsIndex = index
      switch (index) {
        case 0:
          this.date = formatDate(new Date())
          this.time = getBeforeHourTimeNoStrideDay(0.5)
          // this.onClickVideoPlayback()
          break;
        case 1:
          this.date = formatDate(new Date())
          this.time = getBeforeHourTimeNoStrideDay(1)
          // this.onClickVideoPlayback()
          break;
        case 2:
          this.date = formatDate(new Date())
          this.time = getBeforeHourTimeNoStrideDay(2)
          // this.onClickVideoPlayback()
          break;
        default:
          break;
      }
    },
    // 视频回放修改查询日期
    onChangeDate (date) {
      this.date = date
      this.getDisabledRecordTimeList()
    },
    // 视频回放修改查询时间
    onChangeTime (time) {
      this.time = time
    },
    // 重置视频回放
    onClickResetPlayback () {
      this.date = ''
      this.time = []
    },
    // 点击视频回放
    onClickVideoPlayback () {
      // 没有在播的就先选设备
      if (!this.playVideoArr.length) return this.$Message.info('请选择视频设备')
      this.videoPlayback(this.playVideoArr[0])
    },
    // 视频回放
    videoPlayback (items) {
      if (items.eqptype == '车载监控') {
        // this.circleVisible = false
        this.sliderVisible = false
      } else {
        // this.circleVisible = true
        this.sliderVisible = true
        this.playVideo = false
      }
      // items是某个通道
      if (!this.date) return this.$Message.info('请选择日期')
      if (!this.time.length) return this.$Message.info('请选择时间')
      if (this.time[1] === this.time[0]) return this.$Message.info('时间范围必须大于0秒')
      this.playbackStartTime = this.date + ' ' + this.time[0]
      this.playbackEndTime = this.date + ' ' + this.time[1]
      // 关闭上一个回放
      if (this.playVideoArr[0]) {
        if (this.playVideoArr[0].eqptype == '视频设备') {
          this.$refs.httpVideoPlayer[0].onCloseFlacBackVideo()
        }
      }
      this.onSaveCurrentTime()
      // 正在播放的列表
      this.playVideoArr = []
      this.$nextTick(() => {
        this.playVideoArr.push(items)
        // 有在播放的 图标变绿色，没有在播的变成白色 start
        this.syncVideoListCheck(this.data)
        if (this.filterVisible) {
          this.syncVideoListCheck(this.searchList)
        }
        // 有在播放的 图标变绿色，没有在播的变成白色 end
      })
    },
    // 获取视频回放列表  车载是设备返回的列表，网络视频是自己弄得
    onSearchPlaybackVideoList (data) {
      if (data.length > 0) {
        this.playbackVideoArr = data
        this.playbackVideoListVisible = true
        // 手动点击第一个 播放
        this.onClickPlaybackVideoItem(data[0], 0)
      }
    },
    // 修改视频回放进度时间
    onChangePlaybackProgressTime (time) {
      this.playbackProgressTime = time
    },
    // 点击视频回放进度
    onClickPlaybackProgress () {
      if (!this.playbackProgressTime) return this.$Message.info('请选择要拖放的时间')
      let date = this.date + ' ' + this.playbackProgressTime
      let item = this.playbackVideoArr[this.currentPlaybackVideoIndex]
      let boo = isInDateInterval(date, item.startTime, item.endTime)
      if (!boo) return this.$Message.info('请选择当前播放的时间区间')
      // 开始时间是选的时间
      let obj = {
        startTime: date,
        endTime: item.endTime
      }
      // 调用组件的视频回放方法
      if (item.type === 'socket') {
        this.$refs.socketVideoPlayer[0].playbackVideo(obj)
      } else {
        this.$refs.httpVideoPlayer[0].playbackVideo(obj)
      }
    },
    // 点击视频回放item
    onClickPlaybackVideoItem (item, index) {
      this.currentPlaybackVideoIndex = index
      if (item.type === 'socket') {
        this.$refs.socketVideoPlayer[0].playbackVideo(item)
      } else {
        this.$refs.httpVideoPlayer[0].playbackVideo(item)
      }
    },
    // 下载接口成功后触发 获取设施视频回放信息
    getFaclVideoMsg (data, params) {
      this.streamId = data.streamId
      // 进度环
      // console.log(data, '父组件');
      this.faclDownloadTime = data.downloadTime
      this.circleFaclVideo()
    },
    // 播放接口成功后触发
    getPlayVideoMsg (params) {
      // 进度条
      // console.log(params, '父组件');
      this.downloadFaclVideoParams = params
      this.sliderFaclVideo()
    },
    // 下载设施视频的进度环
    circleFaclVideo () {
      let countDown = this.faclDownloadTime / 100
      // console.log(countDown, '下载所需时间');
      this.faclInterval = setInterval(() => {
        this.downCircle++
        if (this.downCircle == 100) {
          clearInterval(this.faclInterval)
          this.downFaclVideo()    // 下载视频
        }
      }, countDown * 1000);
    },
    // 下载设施视频
    downFaclVideo () {
      this.$refs.ordinaryVideo[0].downFaclVideo()
    },
    // 播放设施视频
    playFaclVideo () {
      this.playVideo = true
      this.$refs.ordinaryVideo[0].playFaclVideo()
    },
    // 播放mp4文件
    onPlayFaclVideo () {
      this.sliderVisible = false
      this.circleVisible = false
    },
    // 下载未完成
    downFaclVideoErr (res) {
      // console.log('下载未完成');
      this.downCircle = this.downCircle - 20
      let countDown = this.faclDownloadTime / 100
      this.faclInterval = setInterval(() => {
        this.downCircle++
        if (this.downCircle == 100) {
          clearInterval(this.faclInterval)
          this.downFaclVideo()    // 下载视频
        }
      }, countDown * 1000);
    },
    // 暂停
    onPause () {
      this.onSaveCurrentTime()
      this.videoPause = false
      clearInterval(this.faclPlayInterval)
    },
    // 播放
    onPlay () {
      this.videoPause = true
      this.sliderFaclVideo()
    },
    onStop () {
      this.videoPause = true
    },
    // 设施视频进度条
    sliderFaclVideo () {
      if (this.faclPlayInterval) {
        clearInterval(this.faclPlayInterval)
      }
      let start = new Date(this.downloadFaclVideoParams.startTime).getTime()
      let end = new Date(this.downloadFaclVideoParams.endTime).getTime()
      this.videoLingth = end - start
      // 1000是滑块的最大长度
      let countDown = this.videoLingth / 1000 / this.speed
      console.log(countDown, '+1需要的时间');
      this.faclPlayInterval = setInterval(() => {
        let prevBtn = document.querySelector('.player-box .loadingBox').style.display
        if (prevBtn == 'none') {
          this.videoSlider++
        }
        if (this.videoSlider == 1000) {
          clearInterval(this.faclPlayInterval)
        }
      }, countDown);
    },
    // 进度条回调
    onChangeSlider (value) {
      let unit = this.videoLingth / 1000   // 1进度的视频长度，1000是滑块总长
      let time = unit * value / 1000   // 1进度的视频长度 * value进度 = value进度的时间(ms) / 1000 得秒
      time = parseInt(time)
      this.videoSlider = value
      this.onSaveCurrentTime()
      this.$refs.httpVideoPlayer[0].playbackControlVideo({
        seektime: time
      })
    },
    // 保存当前时间
    onSaveCurrentTime () {
      // 保存当前时间 start
      let start = new Date(this.playbackStartTime).getTime()
      let end = new Date(this.playbackEndTime).getTime()
      let diff = end - start
      this.playbackCurrentTime = diff * (1 - this.videoSlider / 1000)
      // 保存当前时间 end
    },
    // 倍速
    onClickFastForward (speed) {
      this.$refs.httpVideoPlayer[0].onClickFastForward(speed)
    },
    // 完成倍速
    onFinishFastForward (speed) {
      this.speed = speed
      this.sliderFaclVideo()
    },
    // 表格相关 start
    onClickPlayBackTop () {
      let rightContHeight = this.$refs.contRightRef.offsetHeight
      let nowDate = formatDate(new Date())
      if (this.playBackTableStatus == 0) {
        this.playBackTableVisible = true
        this.recordTableHeight = window.innerHeight - 120 - rightContHeight / 2
        this.playBackTableStatus = 1
      } else if (this.playBackTableStatus == 1) {
        this.playBackTableVisible = true
        this.recordTableHeight = window.innerHeight - 120
        this.playBackTableStatus = 2
      }
      this.getVideoFileList(nowDate)
    },
    onClickPlayBackBottom () {
      this.playBackTableVisible = false
      this.playBackTableStatus = 0
    },
    onClickHidePlaybackTable () {
      this.playBackTableVisible = false
      this.playBackTableStatus = 0
    },
    // 录像时间段选择器回调
    onChangeLengthTime (value) {
      this.videoTime = value
      let startDate = new Date(this.date + ' ' + value[0]).getTime()
      let endDate = new Date(this.date + ' ' + value[1]).getTime()
      let diff = endDate - startDate
      if (diff > hourMillisecond * this.videoConfig.limitSpace) {
        this.$Message.info('录像时长最多' + this.videoConfig.limitSpace + '小时')
      }
      this.getDisabledRecordTimeList()
    },
    initTime () {
      let currentTime = formatTime(new Date()).split(':')
      let currentHour = []
      let currentMinute = []
      let currentSecond = []
      let choiceTime = this.makeAnAppointmentTime.split(':')
      for (let i = 0; i < currentTime[0]; i++) {
        currentHour.push(i)
      }
      if ((choiceTime || [])[0] <= currentTime[0]) {
        for (let i = 0; i < currentTime[1]; i++) {
          currentMinute.push(i)
        }
        if ((choiceTime || [])[1] <= currentTime[1]) {
          for (let i = 0; i < currentTime[2]; i++) {
            currentSecond.push(i)
          }
        }
      }
      this.videoTimeDisabledHours = currentHour
      this.videoTimeDisabledMinutes = currentMinute
      this.videoTimeDisabledSeconds = currentSecond
    },
    getDisabledRecordTimeList () {
      let start = new Date(this.date + ' ' + this.videoTime[0]).getTime()
      let end = new Date(this.date + ' ' + this.videoTime[1]).getTime()
      if (start > Date.now()) {
        console.log('开始时间大于当前时间');
        // 录制日期
        this.makeAnAppointmentDateOptions = {
          disabledDate: date => {
            return date && date.valueOf() < new Date(this.date) - dayMillisecond
          }
        }
        // 录制时间 只能选择开始时间之后的时间
        // 录像日期 与 录制日期是同一天 则录制时间只能为录像开始时间之后
        // 录像日期 大于 录制日期 录制时间任意
        if (this.date == this.makeAnAppointmentDate) {
          console.log(this.videoTime[0]);
          console.log('录像日期 与 录制日期是同一天');
          // 录像开始时间数组
          let lengthStartTimeArr = this.videoTime[0].split(':')
          // 录制时间数组
          let makeAnAppointmentTimeArr = this.makeAnAppointmentTime.split(':')
          // 小时
          let hour = []
          let videoTimeHour = parseInt(lengthStartTimeArr[0])
          for (let i = 0; i < videoTimeHour; i++) {
            hour.push(i)
          }
          this.videoTimeDisabledHours = hour
          // 分钟
          // 如果录制时间的小时 等于 录像开始时间的小时 则录制时间的分钟只能小于录像开始时间的分钟
          // 如果录制时间的小时 大于 录像开始时间的小时 则录制时间的分钟任意
          let minute = []
          let videoTimeMinute = parseInt(lengthStartTimeArr[1])
          if (makeAnAppointmentTimeArr[0] == lengthStartTimeArr[0]) {
            console.log('录制时间的小时 等于 录像开始时间的小时');
            for (let i = 0; i < videoTimeMinute; i++) {
              minute.push(i)
            }
            this.videoTimeDisabledMinutes = minute
            // 秒
            // 如果录制时间的分钟 等于 录像开始时间的分钟 则录制时间的分钟只能小于录像开始时间的分钟
            // 如果录制时间的分钟 大于 录像开始时间的分钟 则录制时间的分钟任意
            let secondArr = []
            let videoTimeSecond = parseInt(lengthStartTimeArr[2])
            if (makeAnAppointmentTimeArr[1] == lengthStartTimeArr[1]) {
              console.log('录制时间的分钟 等于 录像开始时间的分钟');
              for (let i = 0; i < videoTimeSecond; i++) {
                secondArr.push(i)
              }
              this.videoTimeDisabledSeconds = secondArr
            } else {
              this.initTime()
            }
          } else {
            this.initTime()
          }
        } else {
          this.initTime()
        }
      } else {
        this.initTime()
        // 录制日期
        this.makeAnAppointmentDateOptions = {
          disabledDate: date => {
            return date && date.valueOf() < Date.now() - dayMillisecond
          }
        }
      }
    },
    // 录制时间 日期回调
    onChangeRecordDate (value) {
      this.makeAnAppointmentDate = value
      if (this.date && this.videoTime.length > 0) {
        this.getDisabledRecordTimeList()
      }
    },
    // 录制时间 时间回调
    onChangeRecordTime (value) {
      this.makeAnAppointmentTime = value
      if (this.date && this.videoTime.length > 0 && this.makeAnAppointmentDate) {
        this.getDisabledRecordTimeList()
      }
    },
    onClickCreatedVideoPlan () {
      if (!this.recordingVideoData.channelid) return this.$Message.info('请选择视频设备')
      let videoStartDate = new Date(this.date + ' ' + this.videoTime[0]).getTime()
      let videoEndDate = new Date(this.date + ' ' + this.videoTime[1]).getTime()
      let diff = videoEndDate - videoStartDate
      if (diff > this.hourMillisecond * this.videoConfig.limitSpace) {
        this.$Message.info('录像时长最多' + this.videoConfig.limitSpace + '小时')
        return
      }
      let nowDate = new Date()
      let params = {
        channel: this.recordingVideoData.channelid,         // 通道
        equipcode: this.recordingVideoData.equipcode,       // 设备编码
        equipId: this.recordingVideoData.id,
        status: 1,           // 0不启用1启用2已删除 ,
        videoDate: this.date,       // 录像日期 ,
        videoStartTime: this.videoTime[0],  //录像开始时间 ,
        videoEndTime: this.videoTime[1],    //录像结束时间 ,
      }
      // 录制开始时间
      if (this.videoStartTime == 1) {
        params.recordTime = this.makeAnAppointmentDate + ' ' + this.makeAnAppointmentTime
      } else {
        params.recordTime = formatDate(nowDate) + ' ' + formatTime(nowDate)
      }
      this.recordLoading = true
      this.$http.createdVideoPlan(params).then((res) => {
        this.$Message.info(res.message)
        if (res.code == 200) {
          this.recordLoading = false
        } else {
          this.recordLoading = false
        }
      }).catch(err => {
        this.recordLoading = false
        this.$Message.info(res.message)
      })
    },
    // 录像文件列表日期选择器回调
    onChangeFileDate (value) {
      this.fileDate = value
    },
    // 录像文件
    getVideoFileList (createTime) {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        startTime: this.fileDate[0],
        endTime: this.fileDate[1],
        channel: this.videoChannel,
        equipcode: this.videoEquipcode,
        createTimeOrder: 'desc',
      }
      if (createTime) {
        params.createTime = createTime
      }
      this.tableLoading = true
      this.$http.getVideoFileList(params).then((res) => {
        if (res.code == 200) {
          this.tableLoading = false
          this.tableData = res.result.rows.map(item => {
            item.playUrl = constantconfig.downLoadUrl + item.url
            return item
          })
          this.totalPage = res.result.total
        }
      }).catch((err) => {
        this.tableLoading = false
      });
    },
    // 录像计划
    getVideoPlanList () {
      let params = {
        channel: this.videoChannel,
        equipcode: this.videoEquipcode,
        // id: '',
        page: this.pageNum,
        // recordTime: '',
        size: this.pageSize,
        createTimeOrder: 'desc',
        // status: '',
        // videoDate: '',
        // videoEndTime: '',
        // videoStartTime: ''
      }
      this.tableLoading = true
      this.$http.getVideoPlanList(params).then((res) => {
        this.tableLoading = false
        this.tableData = res.result.rows
        this.totalPage = res.result.total
      }).catch((err) => {
        this.tableLoading = false
      });
    },
    // 修改录像计划
    onClickEditVideoPlan (row) {
      if (['1', '2', '3',].indexOf(row.fileStatus)) {
        this.$Message.info('此计划已经进入录制阶段，无法进行编辑')
        return
      }
      this.videoProgramPlanVisible = true
      this.videoProgramPlanItem = row
    },
    // 删除录像计划
    onClickDeleteVideoPlan (row) {
      // fileStatus (string, optional): 录像文件状态：0录制未开始1录制中2录制完成3已失效 ,
      if (['1', '2', '3',].indexOf(row.fileStatus)) {
        this.$Message.info('此计划已经进入录制阶段，无法进行删除')
        return
      }
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteVideoPlan(params).then((res) => {
            this.$Message.info(res.message)
            this.getVideoPlanList()
          })
        }
      })
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.select = selection
    },
    onChangeSort (e) {
      this.sortKey = e.key
      this.sortType = e.order
      this.onClickSearch()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      switch (this.currentTabIndex) {
        case 2:
          this.getVideoFileList()
          break;
        case 3:
          this.getVideoPlanList()
          break;
        default:
          break;
      }
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.fileDate = []
      this.videoChannel = ''
      this.videoEquipcode = ''
    },
    // 点击别处关闭录制弹框
    handlerCloseGenDetail () {
      this.recordingVisible = false
    },
    // 下载视频
    onClickDownVideo (row) {
      window.open(row.playUrl);
    },
    onClickDeletePlan () {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            deleteIds: this.selectArr.join()
          }
          this.$http.deleteBatchVideoPlan(params).then((res) => {
            this.$Message.info(res.message)
            this.getVideoPlanList()
          })
        }
      })
    },
    // 预览视频
    onClickPreviewVideo (row) {
      if (row.status == 2) {
        this.previewVideoUrl = row.playUrl
      }
    }
    // 表格相关 end
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/tree";
@import "@/scss/iviewCssReset/select";
@import "@/scss/iviewCssReset/input";
@import "@/scss/iviewCssReset/collapse";
@import "@/scss/iviewCssReset/page";
@import "@/scss/iviewCssReset/table";
@import "@/scss/iviewCssReset/datePicker";
.doubleSpeed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
::v-deep {
  .ivu-poptip-popper {
    .ivu-poptip-inner {
      background-color: #191b1e;
      .ivu-poptip-body {
        padding: 0;
      }
    }
    .ivu-poptip-arrow {
      border-bottom-color: #191b1e;
      &::after {
        border-bottom-color: #191b1e;
      }
    }
  }
  .ivu-collapse-simple {
    .ivu-collapse-item-active {
      .ivu-collapse-header {
        background: #213331;
        border-color: #245a4c;
      }
    }
    .ivu-collapse-header {
      height: 56px;
      display: flex;
      align-items: center;
      border: 1px solid transparent;

      .ivu-icon-ios-arrow-forward {
        order: 1;
      }
    }
  }
  .ivu-time-picker-cells-cell:hover {
    color: #333;
  }
  .ivu-date-picker-rel .ivu-input {
    background-color: #111315;
  }
}
.main {
  height: 100%;
  width: 100%;
  .top {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    display: flex;
    align-items: center;
    z-index: 100;
    background-color: #2c3344;
    .top-cont {
      display: flex;
      flex: 1;
      background: #282a2e url(../../assets/video/net-nav.png) no-repeat 0 100%;
      .top-cont-logo {
        width: 598px;
        height: 56px;
        display: flex;
        padding-left: 5px;
        box-sizing: border-box;
        align-items: center;
        color: #fff;
        margin-right: 50px;
        cursor: pointer;
        img {
          max-width: 100%;
          max-height: 100%;
          margin: 0 8px;
        }
        span {
          margin-left: 8px;
          font-size: 26px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.85);
        }
      }
      .top-cont-tabs {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        height: 56px;
        align-items: center;
        color: #fff;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 40px;
          cursor: pointer;
          transition: color ease-in 0.3s;
        }
        .active {
          background: url(../../assets/video/h_tab_bg.png) no-repeat center
            center;
          color: #26bc96;
        }
      }
    }
  }
  .cont {
    height: 100vh;
    // padding-top: 60px;
    background-color: #2c3344;
    display: flex;
    position: relative;
    .cont-left {
      width: 398px;
      height: 100%;
      background-color: #1a1c1e;
      // position: fixed;
      // z-index: 10;
      .left {
        display: flex;
        flex-direction: column;
        height: 100%;
        .left-list {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: auto;
          .video-tree-title {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            background: linear-gradient(90deg, #205047, #232923);
            padding: 0 12px 0 18px;
            color: #fff;
            .video-tree-title-left {
              i {
                cursor: pointer;
              }
            }
            .video-tree-title-right {
              display: flex;
              align-items: center;
              i {
                cursor: pointer;
              }
            }
            .video-tree-title-right-icon {
              margin-right: 6px;
              cursor: pointer;
            }
          }
          .video-tree-title::after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
            height: 1px;
            background: linear-gradient(90deg, #1eb993, #374a2c);
          }
          .video-tree-info {
            display: flex;
            align-items: center;
            padding: 0 16px;
            height: 80px;
            color: rgba(255, 255, 255, 0.45);
            background-color: #232527;
            .total {
              width: 50%;
              display: flex;
              height: 52px;
              align-items: center;
              img {
                max-width: 42px;
                max-height: 42px;
              }
              div {
                line-height: 21px;
                margin-left: 4px;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: #b3d365;
                }
              }
            }
            .concern {
              width: 50%;
              display: flex;
              height: 52px;
              align-items: center;
              img {
                max-width: 42px;
                max-height: 42px;
              }
              div {
                line-height: 21px;
                margin-left: 4px;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: #b3d365;
                }
              }
            }
            .online {
              width: 50%;
              display: flex;
              height: 52px;
              align-items: center;
              .online-icon {
                width: 42px;
                height: 42px;
                line-height: 42px;
                margin-right: 4px;
                border-radius: 50%;
                text-align: center;
                background-color: #25bb96;
                font-size: 20px;
                color: #25bb96;
              }
              div {
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: rgba(255, 255, 255, 0.65);
                }
              }
            }
          }
          .video-tree-filter {
            display: flex;
            padding: 8px;
          }
          .video-tree-list {
            flex: 1;
            overflow: auto;
            background-color: #191b1e;
            .item {
              .item-parent {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                height: 56px;
                padding: 0 8px;
                color: rgba(255, 255, 255, 0.65);
                box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.6);
                border: 1px solid transparent;
                transition: all 0.4s;
                cursor: pointer;
                .item-parent-left {
                  display: flex;
                  align-items: center;
                  font-weight: 600;
                  span {
                    display: inline-block;
                    max-width: 250px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
                .item-parent-right {
                  display: flex;
                  align-items: center;
                  .item-parent-right-lefticon {
                    margin-right: 5px;
                  }
                  .parent-right-iconshow {
                    transform: rotate(90deg);
                  }
                }
              }
              .item-child {
                li {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 8px;
                  height: 42px;
                  color: #83909d;
                  background-color: #232527;
                  font-size: 12px;
                  transition: all 0.4s;
                  .li-left {
                    display: flex;
                    align-items: center;
                    .status {
                      display: inline-block;
                      width: 42px;
                      height: 22px;
                      line-height: 22px;
                      margin-right: 8px;
                      text-align: center;
                      border: 1px solid #83909d;
                    }
                    .on-line {
                      color: #51c319;
                      border: 1px solid #51c319;
                    }
                    .name {
                      display: inline-block;
                      max-width: 250px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  .li-right {
                    .li-right-icon {
                      cursor: pointer;
                    }
                  }
                  .li-right-iconshow {
                    display: none;
                  }
                }
                li:hover {
                  background-color: #223332;
                  .li-right-iconshow {
                    display: inline-block;
                    margin-right: 8px;
                  }
                }
              }
            }
            .video-tree-list-itemshow {
              background: #213331;
              border-color: #245a4c;
            }
          }
        }
        .left-inquire {
          flex-shrink: 0;
          color: rgba(255, 255, 255, 0.85);
          padding: 22px 0 40px 22px;
          background-color: #242628;
          border-bottom: 2px solid #000;
          font-size: 14px;
          .inquire-title {
            font-size: 16px;
          }
          .inquire-time {
            display: flex;
            margin: 12px 0;
            color: rgba(255, 255, 255, 0.65);
            li {
              position: relative;
              padding: 8px;
              margin-right: 20px;
              transition: all 0.4s;
              cursor: pointer;
            }
            li::after {
              position: absolute;
              bottom: 0;
              left: 24px;
              content: "";
              height: 2px;
              width: 18px;
              background-color: transparent;
            }
            .current {
              color: #26bc96;
            }
            .current::after {
              background-color: #26bc96;
            }
          }
          .inquire-picker {
            display: flex;
            height: 32px;
            margin-bottom: 24px;
            align-items: center;
            color: rgba(255, 255, 255, 0.54);
            p {
              margin-right: 6px;
            }
          }
          .inquire-btns {
            margin-left: 34px;
            button {
              margin-right: 5px;
              cursor: pointer;
              font-size: 14px;
              color: #ffffff;
              border: none;
            }
            button:first-child {
              background: #53575f;
            }
            button:last-child {
              background: #26bc96;
            }
          }
        }
        .left-carousel {
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          height: 56px;
          padding: 0 16px 0 24px;
          margin-bottom: 2px;
          background-color: #232527;
          color: rgba(255, 255, 255, 0.8);
          cursor: pointer;
        }
        .left-concern {
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding: 0 16px 0 24px;
          height: 56px;
          background-color: #232527;
          color: rgba(255, 255, 255, 0.8);
          cursor: pointer;
          .concern-right {
            span {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .cont-search {
      width: 398px;
      background-color: #1a1c1e;
      position: absolute;
      top: 60px;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      .search-input {
        flex-shrink: 0;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        color: #fff;
        background: #225249;
        i {
          cursor: pointer;
        }
        input {
          color: #fff;
        }
      }
      .video-tree-list {
        overflow: auto;
        flex: 1;
        background-color: #191b1e;
        .item {
          .item-parent {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            height: 56px;
            padding: 0 8px;
            color: rgba(255, 255, 255, 0.65);
            box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.6);
            border: 1px solid transparent;
            transition: all 0.4s;
            cursor: pointer;
            .item-parent-left {
              display: flex;
              align-items: center;
              font-weight: 600;
              span {
                display: inline-block;
                max-width: 250px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .item-parent-right {
              display: flex;
              align-items: center;
              .item-parent-right-lefticon {
                margin-right: 5px;
              }
              .parent-right-iconshow {
                transform: rotate(90deg);
              }
            }
          }
          .item-child {
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 8px;
              height: 42px;
              color: #83909d;
              background-color: #232527;
              font-size: 12px;
              transition: all 0.4s;
              .li-left {
                display: flex;
                align-items: center;
                .status {
                  display: inline-block;
                  width: 42px;
                  height: 22px;
                  line-height: 22px;
                  margin-right: 8px;
                  text-align: center;
                  border: 1px solid #83909d;
                }
                .on-line {
                  color: #51c319;
                  border: 1px solid #51c319;
                }
                .name {
                  display: inline-block;
                  max-width: 250px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .li-right {
                .li-right-icon {
                  cursor: pointer;
                }
              }
              .li-right-iconshow {
                display: none;
              }
            }
            li:hover {
              background-color: #223332;
              .li-right-iconshow {
                display: inline-block;
                margin-right: 4px;
              }
            }
          }
        }
        .video-tree-list-itemshow {
          background: #213331;
          border-color: #245a4c;
        }
      }
    }
    .cont-right {
      flex: 1;
      height: 100%;
      display: flex;
      border-left: 1px solid black;
      // margin-left: 398px;
      .right-video {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;
        // 直播底部
        .top-nav {
          width: 100%;
          height: 40px;
          z-index: 99;
          padding-right: 16px;
          background-color: rgba(0, 0, 0, 0.85);
          display: flex;
          justify-content: space-between;
          ul {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            li {
              margin-left: 8px;
              user-select: none;
              img {
                cursor: pointer;
              }
            }
          }
          .right-icon {
            color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            line-height: 40px;
            .right-icon-icon,
            .right-icon-text {
              cursor: pointer;
            }
          }
        }
        // 回放底部.
        .playback-footer {
          padding: 0 20px;
          padding-bottom: 20px;
          color: #fff;
          .slider-current {
            color: #25bb96;
          }
        }
        .right-video-box {
          width: 100%;
          height: calc(100% - 40px);
          background-color: #1d1f23;
          flex: 1;
          .right-video-item {
            float: left;
            position: relative;
            border: 1px solid #393939;
            padding-top: 30px;
            .right-video-item-top {
              height: 30px;
              padding: 0 15px;
              background-color: #1d1f23;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              z-index: 1000;
            }
            .right-video-item-default {
              position: absolute;
              left: 50%;
              top: 50%;
              color: #fff;
              font-size: 56px;
              opacity: 0.2;
              transform: translate(-50%, -50%);
            }
          }
        }
        .video-one {
          .right-video-item {
            flex: 1;
            width: 100%;
            height: 100%;
          }
        }
        .video-four {
          /* flex-wrap: wrap; */
          .right-video-item {
            width: 50%;
            height: 50%;
          }
        }
        .video-six {
          /* flex-wrap: wrap; */
          .right-video-item {
            width: 33.3%;
            height: 50%;
          }
        }
        .video-nine {
          /* flex-wrap: wrap; */
          .right-video-item {
            width: 33.3%;
            height: 33.3%;
          }
        }
        .video-twelve {
          /* flex-wrap: wrap; */
          .right-video-item {
            width: 25%;
            height: 33.3%;
          }
        }
        .video-sixteen {
          /* flex-wrap: wrap; */
          .right-video-item {
            width: 25%;
            height: 25%;
          }
        }
        .video-twenty-four {
          .right-video-item {
            width: 25%;
            height: 16.66%;
          }
        }
        .video-thirty-two {
          .right-video-item {
            width: 25%;
            height: 12.5%;
          }
        }
        .video-sixty-four {
          .right-video-item {
            width: 12.5%;
            height: 12.5%;
          }
        }
        .video-four-one {
          /* flex-wrap: wrap; */
          /* flex-direction: column; */
          .right-video-item {
            width: 25%;
            height: 33.3%;
          }
          .right-video-item:nth-child(1) {
            width: 75%;
            height: 100%;
          }
        }
        .video-six-one {
          /* flex-wrap: wrap; */
          .right-video-item {
            width: 33.3%;
            height: 33.3%;
          }
          .right-video-item:nth-child(1) {
            width: 66.6%;
            height: 66.6%;
          }
        }
        .video-ten-two {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          .right-video-item {
            width: 25%;
            height: 25%;
          }
          .right-video-item:nth-child(5),
          .right-video-item:nth-child(6) {
            width: 50%;
            height: 50%;
          }
        }
        // 回放底部的列表按钮
        .playback-listbtn {
          // width: 100%;
          background: #cccccc;
          position: fixed;
          bottom: 0;
          left: 400;
          z-index: 1001;
          .playback-top,
          .playback-bottom {
            width: 80px;
            text-align: center;
            background: #ffffff;
            position: relative;
          }
          .playback-bottom::after {
            content: "";
            width: 30px;
            height: 20px;
            background: #ccc;
            position: absolute;
            left: -17px;
            top: 7px;
            transform: rotate(60deg);
          }
          .playback-bottom::before {
            content: "";
            width: 30px;
            height: 20px;
            background: #ccc;
            position: absolute;
            right: -17px;
            top: 7px;
            transform: rotate(-60deg);
          }
          .playback-top::after {
            content: "";
            width: 30px;
            height: 20px;
            background: #ccc;
            position: absolute;
            left: -17px;
            top: -3px;
            transform: rotate(120deg);
          }
          .playback-top::before {
            content: "";
            width: 30px;
            height: 20px;
            background: #ccc;
            position: absolute;
            right: -17px;
            top: -3px;
            transform: rotate(-120deg);
          }
          .playback-table {
            background: #232323;
          }
        }
      }
      // 下载进度环
      .down-circle {
        position: fixed;
        bottom: 80px;
        right: 40px;
        color: #fff;
        z-index: 10;
      }
      // 回放列表
      .right-playback {
        width: 33px;
        height: 29px;
        background: rgba(40, 43, 48, 0.8);
        border: 1px solid #2a2e33;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 100px;
        right: 220px;
        z-index: 1000;
        cursor: pointer;
        .right-playback-box {
          background: #191b1e;
          border: 1px solid rgba(217, 217, 217, 0.2);
          padding: 10px 15px;
          border-radius: 4px;
          color: rgba(255, 255, 255, 0.54);
          max-height: 500px;
          .right-playback-item {
            width: 220px;
            height: 70px;
            background: rgba(255, 255, 255, 0.3);
            border: 1px solid #ffffff;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            span {
              font-size: 14px;
              color: #fff;
            }
            &.current {
              background: rgba(37, 187, 150, 0.3);
              border: 1px solid #25bb96;
              span {
                color: #45eab3;
              }
            }
          }
        }
      }
      // 录制按钮
      .right-recording {
        position: fixed;
        bottom: 140px;
        left: 410px;
        z-index: 1000;
        display: flex;
        align-items: flex-end;
        .right-recording-modal {
          background: #63666f;
          padding: 20px;
          border-radius: 10px;
          margin-left: 10px;
          color: #ddd;
          .right-recording-modal-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .item-title {
              min-width: 110px;
            }
          }
        }
      }
    }
  }
}
.group {
  display: flex;
  align-items: center;
  justify-content: center;
  .group-title {
    margin-right: 20px;
    position: relative;
  }
  .group-title::after {
    content: "*";
    color: #ff0000;
    position: absolute;
    top: -5px;
    left: -5px;
  }
  ::v-deep {
    .ivu-input {
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 2px;
    }
    .ivu-modal-header {
      background: #525456;
      .ivu-modal-header-inner {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.videotape {
  background: #232323;
  // margin-left: 398px;
}
</style>