<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="700"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>开启录像计划：</p>
          <RadioGroup v-model="videpPlan">
            <Radio :label="1">是</Radio>
            <Radio :label="0">否</Radio>
          </RadioGroup>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备名称：</p>
          <Select v-model="name"
                  placeholder="请选择设备名称"
                  @on-change="onChangeName">
            <Option v-for="item in nameList"
                    :value="item.equipcode"
                    :key="item.equipcode">{{item.bindobjname}}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设备通道：</p>
          <Select v-model="channel">
            <Option v-for="item in channelList"
                    :value="item.channelid"
                    :key="item.channelid">{{ item.channelname }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>录像长度：</p>
          <DatePicker type="date"
                      :value="lengthDate"
                      @on-change="onChangeVideoDate"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <TimePicker type="timerange"
                      :value="lengthTime"
                      @on-change="onChangeVideoTime"></TimePicker>
        </li>
        <li class="form-ul-li a-center">
          <p class="li-title"><span class="li-must">*</span>录制时间：</p>
          <DatePicker type="date"
                      :value="recordingDate"
                      :options="recordingDateOptions"
                      @on-change="onChangeRecordDate"
                      class="m-r-10"></DatePicker>
          <TimePicker type="time"
                      :value="recordingTime"
                      :disabled-hours="videoTimeDisabledHours"
                      :disabled-minutes="videoTimeDisabledMinutes"
                      :disabled-seconds="videoTimeDisabledSeconds"
                      @on-change="onChangeRecordTime"
                      class="m-r-10"></TimePicker>
          <Checkbox v-model="recordNow">即刻录制</Checkbox>
        </li>
        <!-- <li class="form-ul-li half">
        </li> -->
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { formatDate, formatTime, hourMillisecond, dayMillisecond } from '@/utils/dateRangUtil';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '设置录像计划'
    },
    visible: Boolean,
    item: null
  },
  data () {
    return {
      videpPlan: 1,
      name: '',
      nameLoading: false,
      nameList: [],
      remoteDefaultlabel: '',
      channel: '',
      channelList: [],
      lengthDate: '',
      lengthTime: [],
      recordingDate: '',
      recordingDateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - dayMillisecond
        }
      },
      recordingTime: '',
      videoTimeDisabledHours: [],
      videoTimeDisabledMinutes: [],
      videoTimeDisabledSeconds: [],
      recordNow: false,
      loading: false
    }
  },
  watch: {
    item (newVal) {
      if (newVal) {
        this.videpPlan = parseInt(newVal.status)
        this.name = newVal.equipcode
        this.onChangeName(newVal.equipcode)
        this.lengthDate = newVal.videoStartTime.split(' ')[0]
        this.lengthTime = [newVal.videoStartTime.split(' ')[1], newVal.videoEndTime.split(' ')[1]]
        this.recordingDate = newVal.recordTime.split(' ')[0]
        this.recordingTime = newVal.recordTime.split(' ')[1]
      }
    }
  },
  computed: {
    ...mapGetters({
      'videoConfig': 'getVideoConfig'
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateVideoConfig'
    ]),
    init () {
      // 避免重复调用  video/index已调用
      // this.updateVideoConfig()
      this.timeInit()
      this.getFaclNameList()
    },
    timeInit () {
      let currentTime = formatTime(new Date()).split(':')
      let currentHour = []
      let currentMinute = []
      let currentSecond = []
      let choiceTime = this.recordingTime.split(':')
      for (let i = 0; i < currentTime[0]; i++) {
        currentHour.push(i)
      }
      if (choiceTime[0] && (choiceTime || [])[0] <= currentTime[0]) {
        for (let i = 0; i < currentTime[1]; i++) {
          currentMinute.push(i)
        }
        if ((choiceTime || [])[1] <= currentTime[1]) {
          for (let i = 0; i < currentTime[2]; i++) {
            currentSecond.push(i)
          }
        }
      }
      this.videoTimeDisabledHours = currentHour
      this.videoTimeDisabledMinutes = currentMinute
      this.videoTimeDisabledSeconds = currentSecond
    },
    getFaclNameList () {
      this.$http.getEquipWithChannel().then((res) => {
        if (res.code === 200) {
          this.nameList = res.result
        }
      })
    },
    onChangeName (value) {
      if (value) {
        let index = this.nameList.findIndex(item => {
          return item.equipcode == value
        })
        this.channelList = this.nameList[index].channels
        if (this.item) {
          this.channel = this.item.channel
        }
      }
    },
    // 录像长度日期回调
    onChangeVideoDate (value) {
      this.lengthDate = value
      if (this.lengthTime.length > 0) {
        let startDate = new Date(value + ' ' + this.lengthTime[0]).getTime()
        let endDate = new Date(value + ' ' + this.lengthTime[1]).getTime()
        this.getVideoLength(startDate, endDate)
        this.getDisabledRecordTimeList()
      }
    },
    // 录像长度时间回调
    onChangeVideoTime (value) {
      this.lengthTime = value
      if (this.lengthDate) {
        // 判断录像长度
        let startDate = new Date(this.lengthDate + ' ' + value[0]).getTime()
        let endDate = new Date(this.lengthDate + ' ' + value[1]).getTime()
        this.getVideoLength(startDate, endDate)
        this.getDisabledRecordTimeList()
      }
    },
    // 判断录像长度
    getVideoLength (startDate, endDate) {
      let diff = endDate - startDate
      if (diff > hourMillisecond * this.videoConfig.limitSpace) {
        this.$Message.info('录像时长最多' + this.videoConfig.limitSpace + '小时')
        return false
      }
      return true
    },
    // 录制时间 日期回调
    onChangeRecordDate (value) {
      this.recordingDate = value
      if (this.lengthDate && this.lengthTime.length > 0) {
        this.getDisabledRecordTimeList()
      }
    },
    // 录制时间 时间回调
    onChangeRecordTime (value) {
      this.recordingTime = value
      if (this.lengthDate && this.lengthTime.length > 0 && this.recordingDate) {
        this.getDisabledRecordTimeList()
      }
    },
    getDisabledRecordTimeList () {
      let start = new Date(this.lengthDate + ' ' + this.lengthTime[0]).getTime()
      let end = new Date(this.lengthDate + ' ' + this.lengthTime[1]).getTime()
      let currentTime = formatTime(new Date()).split(':')
      console.log(currentTime);
      let currentHour = []
      let currentMinute = []
      let currentSecond = []
      for (let i = 0; i < currentTime[0]; i++) {
        currentHour.push(i)
      }
      for (let i = 0; i < currentTime[1]; i++) {
        currentMinute.push(i)
      }
      for (let i = 0; i < currentTime[2]; i++) {
        currentSecond.push(i)
      }
      this.videoTimeDisabledHours = currentHour
      this.videoTimeDisabledMinutes = currentMinute
      this.videoTimeDisabledSeconds = currentSecond
      if (start > Date.now()) {
        console.log('开始时间大于当前时间');
        // 录制日期
        this.recordingDateOptions = {
          disabledDate: date => {
            return date && date.valueOf() < new Date(this.lengthDate) - dayMillisecond
          }
        }
        // 录制时间 只能选择开始时间之后的时间
        // 录像日期 与 录制日期是同一天 则录制时间只能为录像开始时间之后
        // 录像日期 大于 录制日期 录制时间任意
        if (this.lengthDate == this.recordingDate) {
          console.log(this.lengthTime[0]);
          console.log('录像日期 与 录制日期是同一天');
          // 录像开始时间数组
          let lengthStartTimeArr = this.lengthTime[0].split(':')
          // 录制时间数组
          let recordingTimeArr = this.recordingTime.split(':')
          // 小时
          let hour = []
          let lengthTimeHour = parseInt(lengthStartTimeArr[0])
          for (let i = 0; i < lengthTimeHour; i++) {
            hour.push(i)
          }
          this.videoTimeDisabledHours = hour
          // 分钟
          // 如果录制时间的小时 等于 录像开始时间的小时 则录制时间的分钟只能小于录像开始时间的分钟
          // 如果录制时间的小时 大于 录像开始时间的小时 则录制时间的分钟任意
          let minute = []
          let lengthTimeMinute = parseInt(lengthStartTimeArr[1])
          if (recordingTimeArr[0] == lengthStartTimeArr[0]) {
            console.log('录制时间的小时 等于 录像开始时间的小时');
            for (let i = 0; i < lengthTimeMinute; i++) {
              minute.push(i)
            }
            this.videoTimeDisabledMinutes = minute
            // 秒
            // 如果录制时间的分钟 等于 录像开始时间的分钟 则录制时间的分钟只能小于录像开始时间的分钟
            // 如果录制时间的分钟 大于 录像开始时间的分钟 则录制时间的分钟任意
            let secondArr = []
            let lengthTimeSecond = parseInt(lengthStartTimeArr[2])
            if (recordingTimeArr[1] == lengthStartTimeArr[1]) {
              console.log('录制时间的分钟 等于 录像开始时间的分钟');
              for (let i = 0; i < lengthTimeSecond; i++) {
                secondArr.push(i)
              }
              this.videoTimeDisabledSeconds = secondArr
            } else {
              this.timeInit()
              // this.videoTimeDisabledSeconds = []
            }
          } else {
            this.timeInit()
            // this.videoTimeDisabledMinutes = []
            // this.videoTimeDisabledSeconds = []
          }
        } else {
          this.timeInit()
          // this.videoTimeDisabledHours = []
          // this.videoTimeDisabledMinutes = []
          // this.videoTimeDisabledSeconds = []
        }
      } else {
        this.timeInit()
        // 录制日期
        this.recordingDateOptions = {
          disabledDate: date => {
            return date && date.valueOf() < Date.now() - dayMillisecond
          }
        }
        // this.videoTimeDisabledHours = []
        // this.videoTimeDisabledMinutes = []
        // this.videoTimeDisabledSeconds = []
      }
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.videpPlan) return this.$Message.info('请选择是否开启录像计划');
      if (!this.name) return this.$Message.info('请选择设备名称');
      if (!this.channel) return this.$Message.info('请选择设备通道');
      if (!this.lengthDate) return this.$Message.info('请选择录像长度');
      if (!this.lengthTime.length) return this.$Message.info('请选择录像长度');
      let startDate = new Date(this.lengthDate + ' ' + this.lengthTime[0]).getTime()
      let endDate = new Date(this.lengthDate + ' ' + this.lengthTime[1]).getTime()
      if (!this.getVideoLength(startDate, endDate)) return
      if (!this.recordNow) {
        if (!this.recordingDate) return this.$Message.info('请选择录制时间');
        if (!this.recordingTime) return this.$Message.info('请选择录制时间');
      }
      let nowDate = new Date()
      let equip = this.nameList.find(item => item.equipcode == this.name)
      let params = {
        channel: this.channel,         // 通道
        equipcode: this.name,       // 设备编码
        equipId: equip.id,
        status: this.videpPlan,           // 0不启用1启用2已删除 ,
        videoDate: this.lengthDate,       // 录像日期 ,
        videoStartTime: this.lengthTime[0],  //录像开始时间 ,
        videoEndTime: this.lengthTime[1],    //录像结束时间 ,
      }
      // 录制开始时间
      if (this.recordNow) {
        // 即刻录制
        // 录像开始时间 大于 当前时间 则即刻录制时间为录制开始时间  反之即刻录制时间为当前时间
        // 2021.12.14 1.9.1 任务1315 录像开始时间 大于 当前时间 则即刻录制时间为录制 结束 时间  反之即刻录制时间为当前时间
        if (startDate > Date.now()) {
          params.recordTime = this.lengthDate + ' ' + this.lengthTime[1]
        } else {
          params.recordTime = formatDate(nowDate) + ' ' + formatTime(nowDate)
        }
      } else {
        params.recordTime = this.recordingDate + ' ' + this.recordingTime
      }
      if (this.item) {
        params.id = this.item.id
        this.loading = true
        this.$http.editVideoPlan(params).then((res) => {
          this.$Message.info(res.message)
          if (res.code == 200) {
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          } else {
            this.loading = false
          }
        }).catch(err => {
          this.loading = false
          this.$Message.info(err.message)
        })
      } else {
        this.loading = true
        this.$http.createdVideoPlan(params).then((res) => {
          this.$Message.info(res.message)
          if (res.code == 200) {
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          } else {
            this.loading = false
          }
        }).catch(err => {
          this.loading = false
          this.$Message.info(res.message)
        })
      }
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:item', null)
        this.resetData()
      }
    },
    resetData () {
      this.videpPlan = 1
      this.name = ''
      // this.nameList = []
      this.channel = ''
      // this.channelList = []
      this.lengthDate = ''
      this.lengthTime = []
      this.recordNow = false
      this.recordingDate = ''
      this.recordingTime = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/iviewCssReset/modal";
@import "@/scss/iviewCssReset/select";
.modal-cont {
  .form-ul {
    color: #fff;
    .form-ul-li {
      .li-title {
        min-width: 110px;
      }
    }
  }
}
</style>