<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>录像保存天数：</p>
          <InputNumber v-model="days"
                       :min="1"
                       controls-outside
                       class="m-r-10"></InputNumber>
          <span class="li-unit">天</span>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>阈值时间：</p>
          <InputNumber v-model="threshold"
                       :min="1"
                       controls-outside
                       class="m-r-10"></InputNumber>
          <span class="li-unit">H</span>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '设置'
    },
    visible: Boolean,
  },
  data () {
    return {
      days: 1,
      threshold: 1,
      loading: false
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        this.getVideoConfig()
      }
    }
  },
  methods: {
    // 获取配置
    getVideoConfig () {
      this.$http.getVideoConfig().then((res) => {
        if (res.code == 200) {
          this.days = parseInt(res.result.limitDay)
          this.threshold = parseInt(res.result.limitSpace)
        }
      })
    },
    // 确定按钮
    onClickConfirm () {
      let params = {
        limitDay: this.days,
        limitSpace: this.threshold
      }
      this.$http.setVideoConfig(params).then((res) => {
        if (res.code == 200) {
          this.$emit('onClickConfirm', params)
          this.$emit('onChange', false)
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        this.$Message.info(res.message)
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.days = 1
      this.threshold = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/iviewCssReset/modal";
.modal-cont {
  .form-ul {
    color: #fff;
    .form-ul-li {
      .li-title {
        min-width: 110px;
      }
      .li-unit {
        color: #fff;
      }
    }
  }
}
::v-deep {
  .ivu-input-number {
    background: transparent;
    border: none;
    .ivu-input-number-controls-outside-btn {
      color: #51c9ab;
      background: transparent;
      border: 1px solid #51c9ab;
      border-radius: 5px;
    }
  }
  .ivu-input-number-input {
    color: #51c9ab;
  }
  .ivu-input-number:focus {
    box-shadow: none;
  }
  .ivu-input-number-focused {
    box-shadow: none;
  }
}
</style>