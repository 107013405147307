<template>
  <div class="ordinaryVideo">
    <video style="width:100%;height:100%"
           autoplay
           controls
           :src="videoUrl"
           :id="dataId+channelId+3"></video>
  </div>
</template>

<script>
import config from '../../config/constantconfig'
import md5 from 'js-md5'
import axios from 'axios'
export default {
  components: {},
  props: {
    dataId: String,
    channelId: String,
    startTime: String,
    endTime: String,
    streamId: String,
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      videoUrl: '',
      mp4Url: '',
      appId: '1010b2b0d85b4018952d89d569a59372',
    };
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },

    // 播放设施视频
    playFaclVideo () {
      // let url = 'http://47.105.65.226:9001/record/rtp/4786943D/2021-11-02/19-49-08.mp4'
      // this.videoUrl = url
      this.videoUrl = this.mp4Url
      this.$emit('onPlayFaclVideo')
    },
    // 下载设施视频
    downFaclVideo () {
      let appId = this.appId
      let deviceId = this.dataId
      let channel = this.channelId
      let timestamp = new Date().getTime()
      let streamId = this.streamId
      let params = {
        appId,
        deviceId,
        channel,
        timestamp,
        streamId
      }
      let sign = md5(`appId=${appId}&channel=${channel}&deviceId=${deviceId}&streamId=${streamId}&timestamp=${timestamp}&secret=123456`)
      params.sign = sign
      axios.post(`${config.httpVideoConfig}/api/playNVR/getDownLoadMp4`, params).then(res => {
        if (res.code === 200) {
          this.mp4Url = res.result
        } else {
          this.$emit('downFaclVideoErr', res)
          this.$Message.info(res.msg)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.ordinaryVideo {
}
</style>