<template>
  <div class="main"
       v-if="videoUrl">
    <video :src="videoUrl"
           controls="controls"
           width="400"
           height="320"
           v-click-outside="handlerClose"
           controlsList="nodownload"
           disablePictureInPicture />
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  components: {},
  directives: { clickOutside: vClickOutside.directive },
  props: {
    videoUrl: String
  },
  data () {
    return {

    };
  },
  watch: {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    handlerClose () {
      this.$emit('update:videoUrl', '')
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
</style>